<template>
  <div class="menu_container">
    <h1 class="text-center">ご注文・お問い合わせ</h1>
    <form>
      <div class="date_container">
        <div class="date_title">
          <h3 class="">お問い合わせ者情報<span>※必須</span></h3>
        </div>
        <div class="date_main">
          <div class="date_input">
            <p>お名前</p>
            <input
              type="text"
              v-model="firstname"
            >
          </div>
          <div class="date_input">
            <p>メールアドレス</p>
            <input
              v-model="email"
              type="email"
            >
          </div>
          <div class="date_input request">
            <p>ご意見・お問い合わせ内容
            </p>
            <input type="text" v-model="request">
          </div>
        </div>
      </div>
    </form>
    <div class="next_btn_container">
        <btn class="next_btn" @click="confirmInformation">▶ 入力した情報を確認する</btn>
    </div>
  </div>
</template>
<script>
import { inject, toRef, ref} from "vue";
import { useRouter } from 'vue-router';

export default {
  name: "ContactForm",
  setup(){
    const firstname = ref();
    const email = ref();
    const request = ref("");
    const router = useRouter();
    const { formState, } = inject("formStore");
    const contactInfo = toRef(formState, "contactInfo");

    const confirmInformation = () => {
      if(!firstname.value){
        alert("お名前を入力してください");
      } else if(!email.value){
        alert("メールアドレスを入力してください");
      } else if(!request.value){
        alert("ご意見・お問い合わせ内容を入力してください");
      } else{
        contactInfo.value.push({
          firstname: firstname.value,
          email: email.value,
          request: request.value,
        });
        router.push("/contactconfirmation");
      }
    };

    return{
      confirmInformation,
      firstname,
      email,
      request,
    }
  }
}
</script>
<style>
.menu_container{
  padding-top: 100px;
  width: 75%;
  font-family: "Noto Sans JP";
}
.text-center{
  letter-spacing: 5px;
}
.date_container{
  padding: 3vw;
  border-bottom:3px solid #333;
}
.date_title{
  padding:1vw;
  & h3{
    font-size:20px;
    font-weight: 550;
    letter-spacing: 5px;
  }
  & span{
    font-size:14px;
    font-weight: 550;
    letter-spacing: 5px;
    color: red;
  }
  & p{
    margin: 1vw;
    margin-bottom: 0;
  }
}
.date_main{
  padding:1vw;
  & p{
    font-size:14px;
    font-weight: 500;
    letter-spacing: 5px;
  }
  & table{
    font-size:14px;
    font-weight: 500;
    letter-spacing: 5px;
  }
  & span{
    font-size: 14px;
    font-weight: 500;
  }
}
.date_input{
  padding: 1vw;
  & input{
    padding:1vw;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 5px;
    border-radius: 5px;
    border: 2px solid #333;
    color:#333;
    width: 100%;
  }
}.time_input{
  padding: 1vw;
  justify-content: space-between;
  display: flex;
  align-items: center;
  & input{
    padding:1vw;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 5px;
    border-radius: 5px;
    border: 2px solid #333;
    color:#333;
    width: 40%;
  }
}
.request{
  & input{
    width:100%;
  }
}
.bill{
  & input{
    padding:1vw;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 5px;
    border-radius: 5px;
    border: 2px solid #333;
    color:#333;
    width: 100%;
  }
}
.radio_input{
  padding: 1vw;
  & input{
    width: 24px;
    height: 24px;
    align-items: center;
    display: flex;
  }
  & label{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 5px;
    align-items: center;
    display: flex;
  }
}
.form-control{
  width:80%;
}
.total_price{
  text-align: right;
  font-size:2vw;
  font-weight: 500;
  letter-spacing: 2px;
}
.next_btn_container {
  text-align: center;
  padding-top: 5vw ;
}
.next_btn {
  display: inline-block;
  padding: 8px 30px 10px 46px;
  border-radius: 22px;
  font-size: 20px;
  background: red;
  background-repeat: no-repeat;
  background-position: 16px 50%;
  background-size: 18px 18px;
  text-align: center;
  font-weight: 700;
  line-height: 1.4;
  cursor: pointer;
  color: white;
  transition: color 0.15s ease-in;
}
input[type=date]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
input[type="date"]::-webkit-inner-spin-button{
  -webkit-appearance: none;
}

input[type="date"]::-webkit-clear-button{
  -webkit-appearance: none;
}

input[type="date"]{
 
  position: relative;
}

input[type=time]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
input[type="time"]::-webkit-inner-spin-button{
  -webkit-appearance: none;
}

input[type="time"]::-webkit-clear-button{
  -webkit-appearance: none;
}

input[type="time"]{
 
  position: relative;
}
.deliveryFeeCalc{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>