// main.ts
import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router'; // Vue Routerをインポート
import HomeView from "./view/HomeView.vue";
import News20231022 from "./NewsList/News20231022.vue";
import MenuList from "./view/MenuList.vue";
import Cart from "./view/Cart.vue";
import CustomerForm from "./view/CustomerForm.vue";
import Confirmation from "./view/Confirmation.vue";
import LastPage from "./view/LastPage.vue";
import ContactForm from "./view/ContactForm.vue";
import ReplyPage from "./view/ReplyPage.vue";
import ContactConfirmation from "./view/ContactConfirmation.vue";



import { createVuetify } from 'vuetify'; // Vuetifyをインポート


const NAME = "とんじき";
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 };
  },
  routes: [
    {
      path: "/",
      name: "HomeView",
      component: HomeView,
      meta: { title: NAME },
    },
    {
      path: "/News/20231022",
      name: "News-20231022",
      component: News20231022,
      meta: { title: NAME + " | News" },
    },
    {
      path: "/menu_list",
      name: "MenuList",
      component: MenuList,
      meta: { title: NAME + " | MenuList" },
    },
    {
      path: "/cart",
      name: "ShopCart",
      component: Cart,
      meta: { title: NAME + " | Cart" },
    },
    {
      path: "/form",
      name: "CustomerForm",
      component: CustomerForm,
      meta: { title: NAME + " | Form" },
    },
    {
      path: "/confirm",
      name: "ConfirmationView",
      component: Confirmation,
      meta: { title: NAME + " | Confirmation" },
    },
    {
      path: "/thank",
      name: "LastPage",
      component: LastPage,
      meta: { title: NAME + " | Thank" },
    },
    {
      path: "/contact",
      name: "ContactForm",
      component: ContactForm,
      meta: { title: NAME + " | ContactForm" },
    },
    {
      path: "/reply",
      name: "ReplyPage",
      component: ReplyPage,
      meta: { title: NAME + " | ReplyPage" },
    },
    {
      path: "/contactconfirmation",
      name: "ContactConfirmation",
      component: ContactConfirmation,
      meta: { title: NAME + " | ContactConfirmation" },
    },
  ],
});
const vuetify = createVuetify();

createApp(App)
  .use(router) // Vue Routerを使用
  .use(vuetify) // Vuetifyを使用
  .mount('#app');