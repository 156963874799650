<template>
  <!-- <div class="home_title">
    <h2> とんじき</h2>
  </div> -->
      <div class="carousel-container">
        <div class="carousel-slide">
          <img :src="items[currentSlide].image" alt="Slide Image">
        </div>
        <div class="carousel-sentence">
          <section id="about">
            <div class="about_container">
              <h2>おむすび家 とんじき</h2>
              <p>手塩にかけて一つ一つ手握りしているとんじきの<br>おむすび
                <br>お米やお塩などの食材は基本的に有機栽培のものや低農薬、天然、無添加のものなどにこだわり、選び抜いたものを使用しております。
                <br>食べてくれた方々が健康になれるような「良いもの」、食べてくれた方々が笑顔になれる「美味しさ」を提供しますので是非ご賞味ください！
              </p>
              <h3 class="ecshop"><router-link :to="{ name: 'MenuList' }"><span class="part1">→ご注文は</span>
                <span class="part2">こちら</span></router-link></h3>
            </div>
          </section>
        </div>
      </div>
      <section id="news">
        <div class="container">
          <h2>NEWS</h2>
          <a href="https://www.instagram.com/tonjiki.jp/">
            <div class="news_btn">
              <h2>→</h2>
              <img src="@\assets\Instagram_Glyph_Black.png" alt="insta Image"/>
              <h2>MORE</h2>
            </div>
          </a>
        </div>
		</section>
    <section id="news">
        <div class="container">
          <h2>定休日</h2>
          <div class="googleCalendar">

            <iframe src="https://calendar.google.com/calendar/embed?height=300&wkst=1&ctz=Asia%2FTokyo&bgcolor=%23ffffff&showTitle=0&showPrint=0&showTabs=0&showTz=0&showCalendars=0&src=NDk5ZjkyZWU1MDgyNmU4NmY0YTBlZTBjNTA4MzY3MTgzOGY3M2I5NGNlNzE3NjZjMWFiMjZhMWI3MGQ2NTA0NkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=amEuamFwYW5lc2UjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23616161&color=%23A79B8E" style="border-width:0" width="400" height="300" frameborder="0" scrolling="no"></iframe>
          </div>
        </div>
		</section>
    <!-- <section id="delivery">
			<div class="container">
				<h2>配達料金</h2>
        <div class="clearfix">
          <p>店舗からの距離が1km未満かつご注文金額が5,000円以下（税抜き）の場合、配達料を500円（税抜き）いただいております。
            <br/>配達距離が1km以上の場合、1km延びるごとに最低注文金額が1,500円（税抜き）ずつ増加し、配達料が150円（税抜き）ずつ加算されます。
            <br/>ただし、注文金額に配達料を加算した合計金額が最低注文金額を超えた場合、配達料金は最低注文金額から注文金額を差し引いた金額となります。
            <br/>例）店舗からの距離が5kmで注文金額の合計が1万円の場合、最低注文金額は11,000円で配達料は1,100円かかるところ1,000円となります。
          </p>
        </div>
			</div>
		</section> -->
    <section id="access">
			<div class="container">
				<h2>店舗概要</h2>
				<div class="clearfix">
					<div class="fltL">
						<p>
							〒114-0002<br>
							東京都北区王子1-6-6<br>
							● 営業時間：5:00～16:00<br>（店舗受取は午前中のみ）<br>
							● 定休日：毎週火曜日と12/29-1/5<br>(臨時休業あり)<br>
      
						</p>
					</div>
					<div class="fltR">
						<p>
              ●TEL：090-3913-3283<br>
							●FAX：03-6903-0213<br>
              ●Mail：tonjiki@tonjiki.co.jp<br>
							●創業：2004年10月1日<br>
							●代表者：長岡志郎<br>
						</p>
					</div>
				</div>
			</div>
		</section>
</template>

<script>
import { ref, onMounted } from "vue";
export default {
  name: 'HomeView',
  setup(){
    const items = [
      { image: require('../assets/slide1.png')},
      { image: require('../assets/slide2.png')},
      { image: require('../assets/slide3.png')},
      ]
    const currentSlide = ref(0);
  
  const startAutoSlide = () => {
      setInterval(() => {
        currentSlide.value = (currentSlide.value + 1) % items.length;
      }, 6000);
  }

    onMounted(() => {
      startAutoSlide();
    })
    return {
      items,
      currentSlide
    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 62.5%;
}

.searchCondition,
.topSerch {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  width: 520px;
  @media screen and (max-width: 767px) {
    width: 320px;
  }
  &_ {
    &keyword {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      height: 50px;
      width: 100%;
    }
  }
}
.searchCondition {
  margin-bottom: 20px;
  &_ {
    &keyword {
      &_ {
        &default,
        &btn {
          cursor: pointer;
          width: 50%;
          height: 75%;

          display: flex;
          justify-content: center;
          align-items: center;
        }
        &default {
          position: relative;
          border-radius: 100vh 0 0 100vh;
          color: #000;
          background-color: #fff;
          transition: all 0.2s;
          p {
            font-weight: 700;
          }
          &::after {
            position: absolute;
            left: 50%;
            border-color: #fff transparent transparent;
            border-style: solid;
            content: "";
            bottom: -8px;
            border-width: 15px 15px 0;
            width: 0;
            height: 0;
            transform: translateX(-50%);
          }
        }
        &btn {
          border-radius: 0 100vh 100vh 0;
          border: 2px solid #fff;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.1);
          transition: all 0.2s;
          &:hover {
            color: #000;
            background: #fff;
            transition: all 0.2s;
            border: 2px solid #fff;
          }
          p {
            font-weight: 700;
          }
        }
      }
    }
  }
}
.topSerch {
  &_ {
    &keyword {
      &_ {
        &input {
          width: 400px;
          height: 100%;
          border-radius: 100vh 0 0 100vh;
          padding: 20px;
        }
        &btn {
          cursor: pointer;
          height: 100%;
          width: 120px;
          border-radius: 0 100vh 100vh 0;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          background-color: #ef0d0d;
          transition: all 0.2s;
          &:hover {
            color: #fff;
            background: #ae0505;
            transition: all 0.2s;
          }
          p {
            font-weight: 700;
          }
        }
      }
    }
  }
}
  h1 {
    display: inline !important;
    color: #fff;
  }

.toplogo-size {
  width: 400px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 767px) {
    width: 240px;
  }
}

.viewMoreBtn {
  cursor: pointer;
  margin: 32px auto 36px;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  display: block;
  text-align: center;
  width: 220px;
}
.container {
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}
.new-card {
  background-color: #fff;
  border-radius: 10px;
  color: #333;
  padding: 20px;
  margin: 10px;
  @media screen and (max-width: 767px) {
    margin: 0 0 4px;
    border-radius: 0;
  }
  h1 {
    margin-bottom: 24px;
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }
}

.c-news-title {
    font-size: 1.4rem;
    color: #333;
}
.c-btn{
  color: #333;
}
.uq-topNews {
    margin-top: 80px;
    margin-bottom: 80px;
    width: 1100px;
    margin-right: auto;
    margin-left: auto;
}
.p-headlineHeader {
    overflow: hidden;
    margin-bottom: 30px;
}
.uq-topNews .l-content {
    padding: 40px;
}
.uq-topNews .p-news_content {
    position: relative;
    overflow-y: scroll;
    height: 290px;
    padding-right: 40px;
}
.p-news_list {
    display: flex;
    margin-bottom: 20px;
}
.p-headlineHeader .c-headline {
    float: left;
    margin-right: 20px;
    margin-bottom: 0;
}
.p-headlineHeader .c-headline+.c-lead {
    position: relative;
    top: 15px;
    margin-top: 0;
    margin-bottom: 0;
}
.c-headline--lv1 .c-headline_string {
    font-size: 34px;
    line-height: 1.5;
}

.p-news_date {
    margin: 0 30px 0 0;
    color: #888;
}
p {
    line-height: 1.8;
}
.p-news_list a {
    text-decoration: none;
}
.deliveryMap{
  width: 70%;
  margin: auto;
  margin-bottom: 50px;
  display: block;
}
.carousel-item{
  width: 100%;
}
.ma-0 {
  margin: 0;
}

.pa-0 {
  padding: 0
}
.carousel-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7); /* 背景の透明度を設定 */
}
.carousel-sentence{
  position: absolute;
  font-size: 3vw;
  color: white;
  padding: 5px;
  margin: 0;
  opacity: 1;
  z-index: 100;
}

.carousel-slide {
  flex: 0 0 100%;
  transition: transform 0.5s ease;
  height: 100vh;
}

/* 画像を横に並べる */
.carousel-slide img {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 1;
  opacity: 0.6;
}

/* スライドアニメーション */
.carousel-slide.active {
  transform: translateX(-100%);
}
.home_title{
  color: #292929;
  letter-spacing:2vw;
  padding-left: 7vw;
  margin-bottom: 0;
  height: 6vw;
  align-items: center;
  display: flex;
  & h2{
    font-size: 4vw;
    font-weight: 500;
  }
}
.box {
  max-width:100vh;
  font-family: "Noto Sans JP";
  display: flex;
  flex-direction: row;
  margin: auto;
  height: 50vh;
}

.detail_main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-top: 10%;
}

.detail_description{
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 18px;
  width: 15%;
  height: 100%;
  margin-right: 5%;
  margin-top: 5%;
  margin-bottom: 5%;
  @media screen and (max-width: 768px){
      font-size: 9px;
  }
}
.detail_img{
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 32px;
  width: 70%;
  text-align: center;
  & img{
    max-width: 100%;
    max-height: 100%;
    margin-right:20%;
    align-items: center;
    justify-content: center;
    text-align: center;
    @media screen and (max-width: 768px){
      margin-right:0;
  }
  }
}
.indi_title {
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 32px;
  margin-top: 50%;
  @media screen and (max-width: 768px){
      font-size: 16px;
  }
}
.number{
  display: flex;
  flex-direction: column;
  font-size: 32px;
  @media screen and (max-width: 768px){
      font-size: 16px;
  }
}
.page_number_before{
  font-size: 16px;

  @media screen and (max-width: 768px){
      font-size: 8px;
  }
}.page_number_after{
  font-size: 16px;
  text-align: right;
  @media screen and (max-width: 768px){
      font-size: 8px;
  }
}
.vertical{
  text-align: center;
  position: absolute;
}
.indi_title_container{
  display: flex;
  flex-direction: column; /* 縦方向に要素を並べる */
}

.box_title {
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 48px;
  width: 20%;
  margin-top: 5%;
  margin-right: 5%;
  @media screen and (max-width: 768px){
      font-size: 24px;
      width: 10%;
  }
}
.box_title_deli{
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 48px;
  width: 20%;
  margin-top: 5%;
  margin-right: 30%;
  @media screen and (max-width: 768px){
      font-size: 24px;
      margin-right:0;
  }
}
.scroll{
  height: 100vh;
}
.order{
  position: fixed;
  bottom: 0;
  left: 0;
}
.order p{
  position: absolute;
  bottom: 30px; /* 位置を調整 */
  left: 30px; /* 位置を調整 */
  color: white;
  padding: 8px 16px;
  font-size: 10px;
}
.deli_main{
  text-align: center;
  align-items: center;
  width: 80%;
  margin-top: 10%;
  & img{
    align-items: center;
    margin-top: 10%;
    width: 100%;
  }
}
.circle1{
  width: 15px;
  height: 15px;
  line-height: 15px;
  background-color: #ff5349ff;
  border-radius: 50%;
  margin-right: 5px;
}
.circle2{
  width: 15px;
  height: 15px;
  line-height: 15px;
  background-color: #f09199ff;
  border-radius: 50%;
  margin-right: 5px;
}
.circle3{
  width: 15px;
  height: 15px;
  line-height: 15px;
  background-color: #ffae42ff;
  border-radius: 50%;
  margin-right: 5px;
}
.circle4{
  width: 15px;
  height: 15px;
  line-height: 15px;
  background-color: #98d98eff;
  border-radius: 50%;
  margin-right: 5px;
}
.price_container1{
  display:flex;
  flex-direction:row;
  align-items: center;
  margin-right: 5px;
}
.delivery_price{
  & ul{
    display: flex;
    flex-direction: row;
    text-align: center;
  }
}
.about_container {
    position: relative;
    letter-spacing: 5px;
    width: 70%;
    max-width: 600px;
    margin: auto;
    padding-top: 10vh;
  & h2 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 65px;
    font-weight: 700 !important;
    @media screen and (max-width: 768px){
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
  & p {
      font-size: 16px;
      line-height: 2.0;
      letter-spacing: 1.5px;
      text-align: justify;
      font-weight: 550;
      @media screen and (max-width: 768px){
      font-size: 12px;
    }
  }
  & span {
    margin: 0;
    padding: 0;
    text-decoration: none;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
    font-size:30px;
    color: white;
  }
}
.ecshop {
    text-align: center;
    margin: 70px 0;
    & a{
      text-decoration: none;
    }
}

#about {
    padding: 10vw 5vw 5vw 5vw;
    @media screen and (max-width: 768px){
      padding: 10vw 5vw 10vw 5vw;
    }
}
#news {
    padding: 5vw;
    border-bottom: 4px solid #000;
}
.container {
    margin: 0 auto;
    max-width: 90vw;
    position: relative;
    width: 80%;
    & h2 {
    font-size: 30px;
    text-align: center;
    font-weight: 700 !important;
  }
  & img{
    width: 40px;
    height: 40px;
    margin: 0 10px;
  }
  & a{
    color:#000;
  }
}
.news_btn{
  display: flex;
  flex-direction: raw;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 2vw;
}
#access {
  padding: 5vw;
}
#delivery {
  padding: 5vw;
  border-bottom: 4px solid #000;
}
.clearfix {
    width: 70%;
    max-width: 700px;
    margin: 0 auto;
    min-height: 1%;
    display: flex;
    flex-direction: raw;
    justify-content: center;
    padding: 2vw;
    & p {
    line-height: 1.75;
    font-weight: 550;
    }
    @media screen and (max-width: 768px){
      flex-direction: column;
    }
}
.fltL {
    width: 47%;
    @media screen and (max-width: 768px){
      width: 100%;
    }
}
.fltR {
    width: 47%;
    @media screen and (max-width: 768px){
      width: 100%;
    }
}
.ecshop .part1,
.ecshop .part2 {
    display: inline;
}
@media (max-width: 600px) {
    .ecshop .part1 {
        display: block;
    }
    .ecshop .part2 {
        display: block;
    }
}
.googleCalendar{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vw;
}
</style>
