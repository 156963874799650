<template>
    <body>
        <div class="l-page">
            <div class="l-containers js-containers">
      <div class="l-content l-content--gray is-noPaddingLeftRight">
          <article class="p-article">
              <header class="p-article_header">
                  <div class="p-article_meta">
                      <p class="p-article_date">2023.10.22</p>
                  </div>

                  <h1 class="p-article_headline c-headline c-headline--lv1">
                      <span class="c-headline_string">10月28日(土)のご予約受付は終了致しました。</span>
                  </h1>
              </header>
              
              <div class="p-article_body">
                  <div class="p-article_main">
                                                      <img src="http://tonjiki.co.jp/wp-content/uploads/sites/7/2020/06/%E3%82%AB%E3%83%AC%E3%83%BC%E3%81%B0%E3%81%8F%E3%81%A0%E3%82%93.jpeg" alt="SNS複数連携について">
                                              </div>

<p>10月22日(土)<br>
イベント日和☀️ですね！<br>
幼稚園のおまつりやロケにお届けしています🚗<br>

10月28日(土)のご注文受付も終了致しました。<br>

⚠️⚠️⚠️<br>
10月22日(日)<br>
10月28日(土)<br>
10月29日(日)<br>
10月30日(月)<br>
11月3日(金・祝)<br>

予約過多の為、ご注文の受付を終了しました。<br>
たくさんのご注文ありがとうございます😊<br>
ご迷惑をおかけしますがよろしくお願い致します。<br>

 

※現在ご注文はFAX、メール、HPの注文カート、ショートメールで受付ております。<br>
お電話ではお受け出来かねます。<br>
ご了承ください。<br>

 

🍀現在、予約対応のみで当日販売はしておりません。<br>
🍀配達可能時間…早朝5時〜17時<br>
詳しくはHP又はお電話でご確認ください</p>
</div>
</article>
</div>
</div>
</div>
</body>
</template>
<script>
export default {
  name: 'News-20231022',
}
</script>
<style>
.l-page {
    /* position: relative; */
    /* z-index: 0; */
    /* overflow: hidden; */
    min-width: 1100px;
    margin: auto;
}
abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
    border: 0;
    background: transparent;
    font-size: 100%;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
    outline: 0;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
}
.l-containers {
    min-height: 80vh;
    margin-bottom: 80px;
    width: 1100px;
    margin-right: auto;
    margin-left: auto;
}
.l-content.is-noPaddingLeftRight {
    padding-left: 0;
    padding-right: 0;
}
.l-content {
    border-radius: 16px;
    background: #fff;
    box-sizing: border-box;
    margin: 0 0 60px;
    padding: 80px;
}
.p-article {
    position: relative;
    width: 700px;
    margin: auto;
}
.p-article_header {
    position: relative;
    margin-bottom: 40px;
}
.p-article_meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    margin-bottom: 10px;
}
.p-article p.p-article_date {
    font-family: Inter;
    color: #b8b8b8;
    display: inline-block;
    margin: 0 10px 0 0;
    font-size: 14px;
    line-height: 1;
}
.p-article_headline {
    margin-bottom: 20px;
}
.c-headline--lv1 {
    margin: 0 0 30px;
}
.c-headline--lv1 .c-headline_string {
    font-size: 34px;
    line-height: 1.5;
}
h1 {
    font-size: 2em;
    font-weight: bold;
}
div {
    display: block;
}
.p-article p {
    font-size: 18px;
    line-height: 1.95;
    margin-bottom: 1.95em;
}
.p-article h3 {
    font-size: 24px;
    margin-top: 2em;
    margin-bottom: 1.2em;
}
body {
    background: #f3f3f3;
    font-family: Noto Sans Japanese,ãƒ’ãƒ©ã‚®ãƒŽè§’ã‚´ Pro W3,Hiragino Kaku Gothic Pro,ãƒ¡ã‚¤ãƒªã‚ª,Meiryo,ï¼­ï¼³ ï¼°ã‚´ã‚·ãƒƒã‚¯,Osaka,sans-serif;
    font-size: 16px;
    line-height: 1.65;
    color: #111;
    width: 100%;
}
</style>