<template>
  <div class="last_container">
    <div class="last_main">
      <p>ご注文いただきありがとうございます！
        <br/>まだ注文は確定しておりません。
        <br/>この後、当方から折り返し、メールかお電話にてご連絡いたします。
        <br/>24時間以内に連絡がない場合、お手数ですがお電話にてご確認いただきますようお願いいたします。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "LastPage",
  setup(){
}
}
</script>
<style>
.last_container{
  padding-top: 10vw;
  width: 100%;
  height: 80vh;
  align-items: center;
  justify-content: center;
  display: flex;
}
.last_main{
  text-align: center;
  font-size:2vw;
  letter-spacing: 2px;
  font-weight: 600;
  font-family: "Noto Sans JP";

}
</style>