import { reactive, ref } from "vue";

export function useMenuStore() {
  const menuState = reactive({
    menuOfBento: [
      { id: 1, name: "巴〜ともえ～", price: 1100, count: 0 },
      { id: 2, name: "藤～ふじ～", price: 1600, count: 0 },
      { id: 3, name: "桔梗～ききょう～", price: 2200, count: 0 },
      { id: 4, name: "葵～あおい～", price: 2700, count: 0 },
      { id: 5, name: "雁〜かり～", price: 1200, count: 0 },
      { id: 6, name: "鶴〜つる～", price: 1600, count: 0 },
      { id: 7, name: "桐〜きり～", price: 1200, count: 0 },
      { id: 8, name: "橘〜たちばな～", price: 1600, count: 0 },
      { id: 9, name: "鶏の唐揚げ弁当", price: 780, count: 0 },
      { id: 10, name: "幕の内弁当", price: 930, count: 0 },
      { id: 11, name: "甘酢あんかけ肉団子弁当", price: 830, count: 0 },
      { id: 12, name: "2種類の焼魚弁当", price: 980, count: 0 },
      { id: 13, name: "鯖の竜田揚げ胡麻だれポン酢弁当", price: 880, count: 0 },
      { id: 14, name: "鶏の唐揚胡麻味噌だれ弁当", price: 830, count: 0 },
      { id: 15, name: "トラウトサーモンの昆布照焼ソース弁当", price: 880, count: 0},
      { id: 16, name: "鯖の味噌煮弁当", price: 880, count: 0 },
      { id: 17, name: "鶏胸肉の梅肉ドレッシング弁当", price: 830, count: 0 },
      { id: 18, name: "塩麹！豚の生姜焼弁当", price: 880, count: 0 },
      { id: 19, name: "鮭わかめご飯弁当", price: 780, count: 0 },
      { id: 20, name: "親子鶏そぼろ弁当", price: 780, count: 0 },
      { id: 21, name: "梅尽くし弁当", price: 980, count: 0 },
      { id: 22, name: "さくら", price: 880, count: 0 },
      { id: 23, name: "かえで", price: 730, count: 0 },
      { id: 24, name: "飛鳥弁当", price: 950, count: 0 },
      { id: 25, name: "王子弁当", price: 680, count: 0 },
      { id: 26, name: "都電弁当", price: 450, count: 0 },
      { id: 27, name: "明治弁当", price: 1150, count: 0 },
      { id: 28, name: "飛鳥弁当(お茶付)", price: 1050, count: 0 },
      { id: 29, name: "王子弁当(お茶付)", price: 780, count: 0 },
      { id: 30, name: "都電弁当(お茶付)", price: 550, count: 0 },
      { id: 31, name: "明治弁当(お茶付)", price: 1250, count: 0 },
    ],
    menuOfOmusubi: [
      {id: 32, name: "酵素玄米塩むすび", price: 230, count: 0},
      {id: 33, name: "酵素玄米鮭", price: 250, count: 0},
      {id: 34, name: "酵素玄米昆布", price: 230, count: 0},
      {id: 35, name: "酵素玄米梅", price: 250, count: 0},
      {id: 36, name: "こだわりの塩むすび", price: 170, count: 0},
      {id: 37, name: "わかめごはん", price: 170, count: 0},
      {id: 38, name: "和風ツナ", price: 170, count: 0},
      {id: 39, name: "海苔佃煮", price: 170, count: 0},
      {id: 40, name: "茄子ごま肉味噌", price: 170, count: 0},
      {id: 41, name: "鶏そぼろ", price: 170, count: 0},
      {id: 42, name: "鶏の唐揚", price: 170, count: 0},
      {id: 43, name: "昆布佃煮", price: 170, count: 0},
      {id: 44, name: "おかか", price: 170, count: 0},
      {id: 45, name: "玉子焼", price: 170, count: 0},
      {id: 46, name: "鶏七味", price: 210, count: 0},
      {id: 47, name: "炙り鮭", price: 190, count: 0},
      {id: 48, name: "炙り鮭と三つ葉", price: 190, count: 0},
      {id: 49, name: "龍神梅", price: 190, count: 0},
      {id: 50, name: "味噌ひじき", price: 230, count: 0},
      {id: 51, name: "赤飯", price: 210, count: 0},
      {id: 52, name: "辛子明太子", price: 190, count: 0},
      {id: 53, name: "焼たらこ", price: 190, count: 0},
      {id: 54, name: "梅ちりめん", price: 210, count: 0},
      {id: 55, name: "しそちりめん", price: 230, count: 0},
      {id: 56, name: "海老天", price: 270, count: 0},
      {id: 57, name: "うなぎ", price: 270, count: 0},
      {id: 58, name: "カレーばくだん", price: 270, count: 0},
      {id: 59, name: "茄子ごま肉味噌(玄米)", price: 170, count: 0},
      {id: 60, name: "鶏そぼろ(玄米)", price: 170, count: 0},
      {id: 61, name: "おかか(玄米)", price: 170, count: 0},
      {id: 62, name: "炙り鮭_玄米", price: 190, count: 0},
      {id: 63, name: "龍神梅(玄米)", price: 190, count: 0},
      {id: 64, name: "炙り鮭と三つ葉(玄米)", price: 190, count: 0},
      {id: 65, name: "玄米雑穀(玄米)", price: 210, count: 0},
      {id: 66, name: "盛合せ容器(大皿)", price: 100, count: 0},
      {id: 67, name: "個包装", price: 10, count: 0},
    ],
    menuOfOsouzai: [
      {id: 68, name: "おこげせんべい", price: 120, count: 0},
      {id: 69, name: "鶏の唐揚", price: 320, count: 0},
      {id: 70, name: "厚焼たまご", price: 140, count: 0},
      {id: 71, name: "切干大根", price: 170, count: 0},
      {id: 72, name: "味噌ひじき", price: 190, count: 0},
      {id: 73, name: "ほうれん草の胡麻和え", price: 170, count: 0},
      {id: 74, name: "南瓜とｿｰｾｰｼﾞのｽﾓｰｸｻﾗﾀﾞ", price: 190, count: 0},
      {id: 75, name: "きのこの黒胡椒炒め", price: 230, count: 0},
      {id: 76, name: "煮物", price: 190, count: 0},
      {id: 77, name: "鶏胸肉の梅肉サラダ", price: 250, count: 0},
      {id: 78, name: "キャベツの塩麹浅漬け", price: 140, count: 0},
      {id: 79, name: "味噌汁", price: 150, count: 0},
      {id: 80, name: "赤飯（一人前200ｇ）", price: 350, count: 0},
      {id: 81, name: "赤飯（一人前200ｇ）", price: 550, count: 0},
      {id: 82, name: "お茶(600ml)", price: 150, count: 0},
      {id: 83, name: "お茶(350ml)", price: 140, count: 0},
      {id: 84, name: "お茶(245g)", price: 100, count: 0},
      {id: 85, name: "お茶 (紙パック250ml)", price: 120, count: 0},
      {id: 86, name: "惣菜盛合せ（中）", price: 1680, count: 0},
      {id: 87, name: "惣菜盛合せ（大）", price: 2780, count: 0}
    ],
    cart: [],
    cartofPack: [],
  });
  const total = ref(0);
  const totalofPack = ref(0);
  const calculateTotal = (cart) => {
    total.value = Math.round(cart.reduce((acc, item) => acc + item.totalPrice, 0));
  };
  const calculateTotalofPack = (cart) => {
    totalofPack.value = Math.round(cart.reduce((acc, item) => acc + item.totalPrice, 0));
  };
  return {
    menuState,
    total,
    calculateTotal,
    totalofPack,
    calculateTotalofPack
  };
}
