<template>
  <div class="menu_container">
    <h1 class="text-center">注文情報入力</h1>
    <div>
      <div class="date_container">
        <div class="date_title">
          <h3 class="">利用方法</h3>
        </div>
        <div class="date_main">
          <div v-if="info[0].where==='delivery'">
            <p>配達
              <br/>{{ info[0].postcode }}
              <br/>{{ info[0].address }}
            </p>
          </div>
          <div v-else>
            <p>お持ち帰り</p>
          </div>
        </div>
      </div>
      <div class="date_container">
        <div class="date_title">
          <h3 class="">受取日時</h3>
        </div>
        <div class="date_main">
          <p>受取日：{{ info[0].date }}
            <br/>受取時間：{{ info[0].time_start }}～{{ info[0].time_end }}
          </p>
        </div>
      </div>
      <div class="date_container">
        <div class="date_title">
          <h3 class="">購入者情報</h3>
        </div>
        <div class="date_main">
          <p>お名前：{{ info[0].firstname }}
            <br/>メールアドレス：{{ info[0].email }}
            <br/>電話番号：{{ info[0].tel }}
            <br/>支払い方法：{{ info[0].howtopay }}
            <br/><span v-if="info[0].bill">領収書・請求書の御宛名：{{ info[0].bill }}</span>
            <br/>お店への要望：{{ info[0].request }}
          </p>
        </div>
      </div>
      <div class="date_container">
        <div class="date_title">
          <h3 class="">注文商品</h3>
        </div>
        <div class="date_main">
          <table>
            <tr v-for="contentofCart in info[0].cart" :key="contentofCart">
              <td>{{ contentofCart.name }}</td>
              <td>×{{ contentofCart.count }}</td>
            </tr>
          </table>
          <table>
            <tr v-for="contentofCart in info[0].cartofPack" :key="contentofCart">
              <td>{{ contentofCart.name }}</td>
              <td>×{{ contentofCart.count }}</td>
            </tr>
            <div v-if="info[0].deliveryFee>0">
              <p>配達料</p>
            </div>
          </table>
            <h3 v-if="info[0].where==='店頭受け取り'" class="total_price">お支払い金額：{{ Math.floor(info[0].total + info[0].totalofPack) }}<span>円</span></h3>
            <h3 v-else class="total_price">お支払い金額：{{ Math.floor(info[0].total + info[0].totalofPack) + info[0].tax + info[0].deliveryFee }}円
              <span><br/>（税抜き金額 8%対象：{{ info[0].total }}円）</span>
              <span><br/>（税抜き金額 10%対象：{{ info[0].totalofPack }}円）</span>
              <span><br/>（配達料（税抜）：{{ info[0].deliveryFee }}円）</span>
              <span><br/>（消費税8%：{{ info[0].tax8 }}円）</span>
              <span><br/>（消費税10%：{{ info[0].tax10 }}円）</span>
            </h3>
        </div>
      </div>
      <div class="date_container">
        <div class="date_title">
        <h3 class="">キャンセル・返品について</h3>
        </div>
        <div class="date_main">
          <p>【お客様都合による返品について】
              商品の性質上、返品はお受けいたしかねます。
              <br/>【不良品等による返品について】
              ご注文のお品と異なる場合や商品の破損、傷みなどがあった場合、
              配達当日にご連絡をいただければ返品をお受けいたします。当方で返品商品の回収をいたします。
              <br/> 
              <br/>前日の午前中までキャンセルをお受けいたします。（キャンセル料はかかりません。）
              <br/>キャンセル料がかかる場合は以下の通りです。
              <br/>◆前日の正午以降	30％
              <br/>◆前日の15：00以降	50％
              <br/>◆当日及びお届け時間12時間以内	100％
              <br/>※又、100食以上の注文、特注注文は2日前の正午からキャンセル料を頂きます。
              <br/>※雨天中止、雨天延期のご予約はご相談下さい。</p>
        </div>
      </div>
    </div>
    <form ref="form" @submit.prevent="sendEmail">
      <div class="sendEmail">
        <label>FirsstName</label>
        <input type="text" v-model="firstName" name="firstname">
        <label>Email</label>
        <input type="email" v-model="email" name="email">
        <label>Info</label>
        <input type="text" v-model="cartContent" name="cartContent">
        <input type="text" v-model="cartofPackContent" name="cartofPackContent">
        <input type="text" v-model="where" name="where">
        <input type="text" v-model="howtopay" name="howtopay">
        <input type="text" v-model="postcode" name="postcode">
        <input type="text" v-model="address" name="address">
        <input type="text" v-model="date" name="date">
        <input type="text" v-model="time_start" name="time_start">
        <input type="text" v-model="time_end" name="time_end">
        <input type="text" v-model="tel" name="tel">
        <input type="text" v-model="bill" name="bill">
        <input type="text" v-model="request" name="request">
        <input type="text" v-model="total" name="total">
        <input type="text" v-model="totalIncludingTax" name="totalIncludingTax">
        <input type="text" v-model="tax" name="tax">
        <input type="text" v-model="deliveryFee" name="deliveryFee">
        <input type="text" v-model="tax8" name="tax8">
        <input type="text" v-model="tax10" name="tax10">
        <input type="text" v-model="totalofPack" name="totalofPack">
      </div>
      <div class="next_btn_container_confirm">
        <btn class="back_btn" @click="backPage">◀ 戻る</btn>
        <input type="submit" value="▶ 注文する" class="next_btn">
      </div>
    </form>
  </div>
</template>
<script>
import emailjs from '@emailjs/browser';
import {inject, toRef, ref} from "vue";
import { useRouter } from 'vue-router';
export default {
  name: "ConfirmationView",
  setup(){
    const { formState, } = inject("formStore");
    const info = toRef(formState, "info");
    const firstName = ref(info.value[0]["firstname"]);
    const email = ref(info.value[0]["email"]);
    const cart = ref(info.value[0]["cart"]);
    const cartContent = cart.value.map(item => `${item.name} (数量: ${item.count}, 価格: ${item.price}円)`).join('\n');
    const cartofPack = ref(info.value[0]["cartofPack"]);
    const cartofPackContent = cartofPack.value.map(item => `${item.name} (数量: ${item.count}, 価格: ${item.price}円)`).join('\n');
    const where = ref(info.value[0]["where"]);
    const howtopay = ref(info.value[0]["howtopay"]);
    const postcode = ref(info.value[0]["postcode"]);
    const address = ref(info.value[0]["address"]);
    const date = ref(info.value[0]["date"]);
    const time_start = ref(info.value[0]["time_start"]);
    const time_end = ref(info.value[0]["time_end"]);
    const tel = ref(info.value[0]["tel"]);
    const bill = ref(info.value[0]["bill"]);
    const request = ref(info.value[0]["request"]);
    const total = ref(info.value[0]["total"]);
    const totalofPack = ref(info.value[0]["totalofPack"]);
    const tax = ref(info.value[0]["tax"]);
    const deliveryFee = ref(info.value[0]["deliveryFee"]);
    const totalIncludingTax = ref(info.value[0]["total"] + info.value[0]["totalofPack"] + info.value[0]["tax"] + info.value[0]["deliveryFee"]);
    const tax8 = ref(info.value[0]["tax8"]);
    const tax10 = ref(info.value[0]["tax10"]);

    const form = ref()
    const router = useRouter();
    const backPage = () => {
      router.push("/form")
    }
    const sendEmail = () => {
      if (form.value) {
      emailjs
        .sendForm('service_l4e1iwk', 'template_iy59zfc', form.value, {
          publicKey: 'ckx57mkr60ZCDAdQz',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            emailjs.sendForm('service_l4e1iwk', 'template_7542teq', form.value, {
              publicKey: 'ckx57mkr60ZCDAdQz',
            });
            router.push("/thank")
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
      }else{
        console.error('Form reference not found!');
      }
    };
    return{
      info,
      sendEmail,
      firstName,
      email,
      form,
      where,
      howtopay,
      cartContent,
      cartofPackContent,
      postcode,
      address,
      date,
      time_start,
      time_end,
      tel,
      bill,
      request,
      backPage,
      total,
      tax,
      totalIncludingTax,
      deliveryFee,
      tax8,
      tax10,
      totalofPack
    }
  }
}
</script>
<style>
.form{
  display: none;
}
.sendEmail{
  display: none;
}
.menu_container{
  padding-top: 100px;
  width: 75%;
  font-family: "Noto Sans JP";
}
.text-center{
  letter-spacing: 5px;
}
.date_container{
  padding: 3vw;
  border-bottom:3px solid #333;
}
.date_title{
  padding:1vw;
  & h3{
    font-size:20px;
    font-weight: 550;
    letter-spacing: 5px;
  }
}
.date_main{
  padding:1vw;
  & p{
    font-size:14px;
    font-weight: 500;
    letter-spacing: 5px;
  }
  & table{
    font-size:14px;
    font-weight: 500;
    letter-spacing: 5px;
  }
  & span{
    font-size: 14px;
    font-weight: 500;
  }
}
.date_input{
  padding: 1vw;
  & input{
    padding:1vw;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 5px;
    border-radius: 5px;
    border: 2px solid #333;
    color:#333;
  }
}
.radio_input{
  padding: 1vw;
  & input{
    width: 24px;
    height: 24px;
    align-items: center;
    display: flex;
  }
  & label{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 5px;
    align-items: center;
    display: flex;
  }
}
.form-control{
  width:80%;
}
.total_price{
  text-align: right;
  font-size:2vw;
  font-weight: 500;
  letter-spacing: 2px;
}
.next_btn_container_confirm{
  text-align: center;
  padding-top: 5vw ;
  display: flex;
  justify-content: space-between;
}
.next_btn {
  display: inline-block;
  padding: 8px 30px 10px 30px;
  border-radius: 22px;
  font-size: 20px;
  background: red;
  background-repeat: no-repeat;
  background-position: 16px 50%;
  background-size: 18px 18px;
  text-align: center;
  font-weight: 700;
  line-height: 1.4;
  cursor: pointer;
  color: white;
  transition: color 0.15s ease-in;
  border:none;
}
.back_btn {
  display: inline-block;
  padding: 8px 30px 8px 30px;
  border-radius: 22px;
  font-size: 16px;
  background: rgb(202, 202, 202);
  background-repeat: no-repeat;
  background-position: 16px 50%;
  background-size: 18px 18px;
  text-align: center;
  font-weight: 700;
  line-height: 1.4;
  cursor: pointer;
  color: white;
  transition: color 0.15s ease-in;
  border:none;
}
</style>