<template>
  <footer id="footer">
			<div class="footer-container">
				<p id="footer-address" class="fltL">
					〒114-0002<br>
					東京都北区王子1-6-6<br>
					TEL：<a href="tel:090-3913-3283">090-3913-3283</a><br>
          FAX：03-6903-0213<br>
          ● 営業時間：5:00～16:00<br>（店舗受取は午前中のみ）<br>
          ● 定休日：毎週火曜日と12/29-1/5(臨時休業あり)<br>
				</p>
				<div id="footer-sns" class="footer-fltR">
          <a href="https://www.instagram.com/tonjiki.jp/">
            <img src="@\assets\Instagram_Glyph_Black.png" alt="INSTAGRAM">
          </a>
          <router-link :to="{ name: 'ContactForm' }">
            <img src="@/assets/mail_black.png" alt="とんじき">
          </router-link>
          <div></div>
				</div>
			</div>
	</footer>
</template>

<script>
export default {
  name: "ShopFooter",
  setup() {
    return {};
  },
};
</script>
<style>
#footer{
  position: relative;
  border-top: 3px solid #000;
}
.footer-container {
    padding: 3vw;
    width: 90%;
    max-width: 700px;
    margin: 0 auto;
    min-height: 1%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    & p {
    line-height: 1.75;
    font-weight: 550;
    }
    & a{
      color: #000;
    }
}

.footer-container #footer-address {
    font-size: 13px;
    line-height: 1.8;
    margin: 0 0 0 60px;
}
.footer-container #footer-sns {
    width: 189px;
  }
  .footer-fltR{
    & img{
      width: 40px;
      height: 40px;
    }
    margin: 1vw;
    justify-content: space-between;
    align-items: center;
    display: flex;
}
</style>
