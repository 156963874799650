<template>
  <div class="wrapper">
    <transition name="name-fade">
      <side-menu v-if="menuState" @openmenu="openMenu"/>
    </transition>
    <div class="main-pane">
      <Navbar @openmenu="openMenu"/>
      <router-view/>
      <ShopFooter/>
    </div>
  </div>
</template>

<script>
import "@fontsource/noto-sans-jp";
import Navbar from './components/Navbar.vue'
import SideMenu from './components/SideMenu.vue'
import ShopFooter from './components/ShopFooter.vue'
import {provide, ref, watch } from "vue";
import { useRoute } from 'vue-router';
import { useMenuStore } from './store/menu-store.js'
import { useFormStore } from './store/form-store.js'


export default {
  name: 'App',

  components: {
    Navbar,
    SideMenu,
    ShopFooter
  },
  setup(){
    provide("menuStore", useMenuStore());
    provide("formStore", useFormStore());
    const route = useRoute();
    const menuState = ref(false);
    const setMeta = (route) => {
      if(route.meta.title){
        let setTitle = route.meta.title;
        document.title = setTitle
      }
    }
    const openMenu = () => {
      menuState.value = !menuState.value;
    }
    watch(route, ()=>{
      setMeta(route);
    });

    //  // スクロールイベントリスナーを追加
    //  window.addEventListener('scroll', function() {
    //   var menuItems = document.querySelectorAll('.order');
    //   // ビューポートの高さを取得
    //   var vh = 140;
    //   // スクロール位置が100vh未満かどうかをチェック
    //   if (window.scrollY > vh) {
    //     // 100vhを超えたら色を#333に変更
    //     menuItems.forEach(function(item) {
    //       item.style.backgroundColor = '#f3f3f3f3';
    //     });
    //   } else{
    //     menuItems.forEach(function(item) {
    //       item.style.backgroundColor = 'transparent';
    //     });
    //   }
    // });
    return{
      route,
      menuState,
      openMenu
    }
  }
}
</script>
<style>
.wrapper{
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}
.main-pane{
  flex: 1 0 auto;
  max-width: 100vw;
}
</style>