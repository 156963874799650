<template>
  <div class="menu_container">
    <h1 class="text-center">問い合わせ情報確認</h1>
    <div>
      <div class="date_container">
        <div class="date_main">
          <p>お名前：{{ contactInfo[0].firstname }}
            <br/>メールアドレス：{{ contactInfo[0].email }}
            <br/>お店への要望：{{ contactInfo[0].request }}
          </p>
        </div>
      </div>
    </div>
    <form ref="form" @submit.prevent="sendEmail">
      <div class="sendEmail">
        <label>FirstName</label>
        <input type="text" v-model="firstName" name="firstname">
        <label>Email</label>
        <input type="email" v-model="email" name="email">
        <label>Info</label>
        <input type="text" v-model="request" name="request">
      </div>
      <div class="next_btn_container_confirm">
        <btn class="back_btn" @click="backPage">◀ 戻る</btn>
        <input type="submit" value="▶ 送信する" class="next_btn">
      </div>
    </form>
  </div>
</template>
<script>
import emailjs from '@emailjs/browser';
import {inject, toRef, ref} from "vue";
import { useRouter } from 'vue-router';
export default {
  name: "ContactConfirmation",
  setup(){
    const { formState, } = inject("formStore");
    const contactInfo = toRef(formState, "contactInfo");
    const firstName = ref(contactInfo.value[0]["firstname"]);
    const email = ref(contactInfo.value[0]["email"]);
    const request = ref(contactInfo.value[0]["request"]);
    const form = ref()
    const router = useRouter();
    const backPage = () => {
      router.push("/contact")
    }
    const sendEmail = () => {
      if (form.value) {
      emailjs
        .sendForm('service_uniua2f', 'template_0iuiqgl', form.value, {
          publicKey: 'Xq052xv0IbhPQSqSp',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            emailjs.sendForm('service_uniua2f', 'template_yqyw29t', form.value, {
              publicKey: 'Xq052xv0IbhPQSqSp',
            });
            router.push("/reply")
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
      }else{
        console.error('Form reference not found!');
      }
    };
    console.log(form.value)
    return{
      contactInfo,
      sendEmail,
      firstName,
      email,
      form,
      request,
      backPage,
    }
  }
}
</script>
<style>
.form{
  display: none;
}
.sendEmail{
  display: none;
}
.menu_container{
  padding-top: 100px;
  width: 75%;
  font-family: "Noto Sans JP";
}
.text-center{
  letter-spacing: 5px;
}
.date_container{
  padding: 3vw;
  border-bottom:3px solid #333;
}
.date_title{
  padding:1vw;
  & h3{
    font-size:20px;
    font-weight: 550;
    letter-spacing: 5px;
  }
}
.date_main{
  padding:1vw;
  & p{
    font-size:14px;
    font-weight: 500;
    letter-spacing: 5px;
  }
  & table{
    font-size:14px;
    font-weight: 500;
    letter-spacing: 5px;
  }
  & span{
    font-size: 14px;
    font-weight: 500;
  }
}
.date_input{
  padding: 1vw;
  & input{
    padding:1vw;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 5px;
    border-radius: 5px;
    border: 2px solid #333;
    color:#333;
  }
}
.radio_input{
  padding: 1vw;
  & input{
    width: 24px;
    height: 24px;
    align-items: center;
    display: flex;
  }
  & label{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 5px;
    align-items: center;
    display: flex;
  }
}
.form-control{
  width:80%;
}
.total_price{
  text-align: right;
  font-size:2vw;
  font-weight: 500;
  letter-spacing: 2px;
}
.next_btn_container_confirm{
  text-align: center;
  padding-top: 5vw ;
  display: flex;
  justify-content: space-between;
}
.next_btn {
  display: inline-block;
  padding: 8px 30px 10px 30px;
  border-radius: 22px;
  font-size: 20px;
  background: red;
  background-repeat: no-repeat;
  background-position: 16px 50%;
  background-size: 18px 18px;
  text-align: center;
  font-weight: 700;
  line-height: 1.4;
  cursor: pointer;
  color: white;
  transition: color 0.15s ease-in;
  border:none;
}
.back_btn {
  display: inline-block;
  padding: 8px 30px 8px 30px;
  border-radius: 22px;
  font-size: 16px;
  background: rgb(202, 202, 202);
  background-repeat: no-repeat;
  background-position: 16px 50%;
  background-size: 18px 18px;
  text-align: center;
  font-weight: 700;
  line-height: 1.4;
  cursor: pointer;
  color: white;
  transition: color 0.15s ease-in;
  border:none;
}
</style>