<template>
  <div class="header-wrapper">
    <header class="header">
      <!-- <ul :class="['menu', {'menu_home': route.path==='/'}]">
        <li @click="scrollToFeatures()">とんじきの特徴</li>
        <li>メニュー</li>
        <li>ご注文の流れ</li>
        <li @click="scrollToDeliMap()">配送エリア</li>
        <li>店舗概要</li>
      </ul> -->
      <div class="logo">
        <router-link :to="{ name: 'HomeView' }">
          <img src="@/assets/logo.png" alt="とんじき">
        </router-link>
      </div>
      <div v-if="route.path==='/'" class="mail">
        <router-link :to="{ name: 'ContactForm' }">
          <img src="@/assets/メールの無料アイコンその8.png" alt="とんじき">
        </router-link>
      </div>
    </header>
    <!-- <div class="navbar-responsive" @click="onClickMenu">
      <img src="@/assets/images/hamburger-btn.png" alt="とんじき_メニュー">
    </div> -->
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
export default {
  name: "ShopNavbar",
  // props: { isSignedIn: {} },
  emits: ["openmenu"],
  setup(_, {emit}) {
    const route = useRoute();
    const viewportHeight = window.innerHeight;
    const onClickMenu = () => {
      // サイドメニューの開閉イベントを発行
      emit("openmenu");
    };
    const scrollToFeatures = () => {
      window.scrollTo({
        top:viewportHeight,
        behavior: "smooth",
      })
    };
    const scrollToDeliMap = () => {
      window.scrollTo({
        top:viewportHeight*1.5,
        behavior: "smooth",
      })
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', function() {
      var menuItems = document.querySelectorAll('.menu_home li');
      // ビューポートの高さを取得
      var vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      
      // スクロール位置が100vh未満かどうかをチェック
      if (window.scrollY < vh) {
        menuItems.forEach(function(item) {
          item.style.color = 'white';
        });
      } else {
        menuItems.forEach(function(item) {
          item.style.color = '#333';
        });
      }
    });
    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', function() {
      var menuItems = document.querySelectorAll('.menu_home li a, menu_home li a:visited');
      // ビューポートの高さを取得
      var vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      
      // スクロール位置が100vh未満かどうかをチェック
      if (window.scrollY < vh) {
        // 100vh未満なら色を白に変更
        menuItems.forEach(function(item) {
          item.style.color = 'white';
        });
      } else {
      // 100vhを超えたら色を#333に変更
        menuItems.forEach(function(item) {
          item.style.color = '#333';
        });
      }
    });

    return { onClickMenu, route, scrollToFeatures, scrollToDeliMap };
  },
};
</script>


<style>
.header-wrapper {
  position: relative;
}

.background-image {
  width: 100%; /* 画像を全幅に表示 */
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  justify-content: space-between;
  z-index: 101;
}

.logo {
  width: 10%; /* ロゴのサイズを適宜調整 */
  height: auto;
  z-index: 100;
  margin-top: 0px;
  @media screen and (max-width: 768px){
    display: none;
  }
}

.menu {
  display: none;
  flex-direction: column;
  width: 15%; /* メニュー項目を縦に配置 */
  padding: 10px;
  &ul{
    list-style-type: none;
  }
  @media screen and (max-width: 768px){
    display: none;
  }
}

.navbar-responsive{
  display: none;
  @media screen and (max-width: 768px){
    padding-left: 10px;
    display: flex;
    align-items: center;
    height: 50px;
    width: 50px;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    & img{
      width: 24px;
    }
  }
}
.menu_home{
  display: flex;
  flex-direction: column;
  width: 15%; /* メニュー項目を縦に配置 */
  padding: 10px;
  color: white;
  @media screen and (max-width: 768px){
    display: none;
  }
}

ul{
    list-style-type: none;
  }
a{
  text-decoration: none;
}

.menu_home li {
  margin-bottom: 10px; /* 各メニュー項目の間に余白を追加 */
  cursor: pointer;
  color: white;
  font-weight: 600;
}

.menu_home li a, menu_home li a:visited {
  color:white;
}
.mail{
  padding: 1vw;
  & img{
    width: 40px;
    height:40px;
  }
  @media screen and (max-width: 768px){
    padding-left: 10px;
    display: flex;
    align-items: center;
    height: 50px;
    width: 50px;
    z-index: 200;
    position: relative;
    top: 5;
    left: 5;
    & img{
      width: 30px;
      height:30px;
    }
  }
}
</style>
