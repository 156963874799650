import { reactive } from "vue";

export function useFormStore() {

  const formState = reactive({
    info : [],
    contactInfo : [],
})

  return {
    formState,
  }
}
