<template>
  <div class="menu_container">
    <div><p>{{ total }}</p></div>
    <p>{{ cart[0].name }}</p>
    <p>{{ cart[0].price }}</p>
    <p>{{ cart[0].count }}</p>
    <div>支払い方法: {{ howtopay }}</div>
    <input type="radio" id=1 value="cash" v-model="howtopay" />
    <label for=1>現金で払う</label>
    <input type="radio" id=2 value="paypay" v-model="howtopay" />
    <label for=2>PayPayで払う</label>
    <input type="radio" id=3 value="bill" v-model="howtopay" />
    <label for=3>請求書で払う</label>
    <div><input type="text" v-model="request"></div>
    <router-link :to="{ name: 'CustomerForm' }">
      <btn>次へ進む</btn>
    </router-link>
  </div>
  
</template>
<script>
import {inject, toRef, ref} from "vue";
export default {
  name: "ShopCart",
  setup(){
    const { total, menuState } = inject("menuStore");
    const cart = toRef(menuState, "cart");
    const howtopay = ref("");
    const request = ref("");
    return{
      total,
      cart,
      howtopay,
      request,
    }
  }
}
</script>
<style>
.menu_container{
  padding-top: 100px;
  width: 75%;
}
</style>