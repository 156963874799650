<template>
  <div @click="closeMenu">
    <nav class="drawer-menu" @click.stop="dummy">
      <div class="drawer-menu__close">
        <div class="close_container" @click="closeMenu">
          <img src="@/assets/close.svg">
        </div>
      </div>
      <div class="drawer-menu__inner">
        <ul>
          <li>
            <router-link :to="{ name: 'HomeView' }"><p>Home</p></router-link>
          </li>
          <li>
            <router-link :to="{ name: 'MenuList' }"><p>ご注文はこちらから</p></router-link>
          </li>
        </ul>
      </div>
    </nav>
    </div>
</template>

<script>
import {mdiClose} from '@mdi/js'
import { watch } from 'vue';
import { useRoute } from 'vue-router';
export default {
  name: "SideMenu",
  emits: ["openmenu"],
  data: () => ({
    mdiClose
  }),
  setup(_, {emit}) {
    const route = useRoute();
    watch(route, () => {
      closeMenu();
    })
    const closeMenu = () => {
      emit("openmenu");
    };
    const dummy = () => {
    };

    
    return { 
      closeMenu,
      dummy,
     };
  },
};
</script>
<style>
.drawer-menu{
  width: 100vw;
  height: 100vh;
  z-index: 200;
  background-color: rgb(243,243,243, 1);
  display: flex;
  flex-direction: column;
}
.drawer-menu__inner{
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  & p{
    color: #333;
    padding: 1vw;
    letter-spacing: 2px;
    font-family: "Noto Sans JP";
  }
  }
  .close_container{
    z-index: 201;
  }
.drawer-menu__close{
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & img{
    width:40px;
    height:40px;
  }
}
</style>