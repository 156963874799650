<template>
  <div class="menu_container">
    <h1 class="text-center">メニュー一覧</h1>
    <div class="tab_list_containier">
      <ul class="tab_list">
        <li @click="onClickBentoMenu" :class="['tab_li', {'tab_selected': selectedMenu==='bento'}]">
          <span> お弁当 </span>
        </li>
        <li @click="onClickOmusubiMenu" :class="['tab_li', {'tab_selected': selectedMenu==='omusubi'}]">
          <span> おむすび </span>
        </li>
        <li @click="onClickSideMenu" :class="['tab_li', {'tab_selected': selectedMenu==='side'}]">
          <span> お惣菜 </span>
        </li>
      </ul>
    </div>
    <div v-if="selectedMenu === 'bento'">
      <div class="title_kamado">
        <h2>かまど炊き特選弁当</h2>
      </div>
      <ul class="menu_kamado">
        <li>
          <img
            src="../assets/かまど炊き特選弁当/tomoe.jpg"
            alt="tomoe"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[0].name }}</p>
            <p>
              <span class="menu_price"> {{ menuOfBento[0].price  }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[0].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(1, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[0].count }}</p>
                <button @click="changeItemAmount(1, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/かまど炊き特選弁当/fuji.jpg"
            alt="fuji"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[1].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[1].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[1].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(2, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[1].count }}</p>
                <button @click="changeItemAmount(2, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/かまど炊き特選弁当/kikyou.jpg"
            alt="kikyou"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[2].name }}</p>
            <p>
              <span class="menu_price"> {{ menuOfBento[2].price  }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[2].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(3, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[2].count }}</p>
                <button @click="changeItemAmount(3, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/かまど炊き特選弁当/aoi.jpg"
            alt="aoi"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[3].name }}</p>
            <p>
              <span class="menu_price"> {{ menuOfBento[3].price  }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[3].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(4, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[3].count }}</p>
                <button @click="changeItemAmount(4, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/かまど炊き特選弁当/kari.jpg"
            alt="kari"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[4].name }}</p>
            <p>
              <span class="menu_price"> {{ menuOfBento[4].price  }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[4].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(5, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[4].count }}</p>
                <button @click="changeItemAmount(5, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/かまど炊き特選弁当/tsuru.jpg"
            alt="tsuru"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[5].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[5].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[5].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(6, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[5].count }}</p>
                <button @click="changeItemAmount(6, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/かまど炊き特選弁当/kiri.jpg"
            alt="kiri"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[6].name }}</p>
            <p>
              <span class="menu_price"> {{ menuOfBento[6].price  }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[6].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(7, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[6].count }}</p>
                <button @click="changeItemAmount(7, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/かまど炊き特選弁当/tashibana.jpg"
            alt="tachibana"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[7].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[7].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[7].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(8, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[7].count }}</p>
                <button @click="changeItemAmount(8, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="title_kamado">
        <h2>お値打ち弁当</h2>
      </div>
      <ul class="menu_kamado">
        <li>
          <img
            src="../assets/お値打ち弁当/鶏の唐揚げ弁当.jpg"
            alt="鶏の唐揚げ弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[8].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[8].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[8].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(9, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[8].count }}</p>
                <button @click="changeItemAmount(9, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/お値打ち弁当/幕の内弁当.jpg"
            alt="幕の内弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[9].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[9].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[9].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(10, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[9].count }}</p>
                <button @click="changeItemAmount(10, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/お値打ち弁当/甘酢あんかけ肉団子弁当.jpg"
            alt="甘酢あんかけ肉団子弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[10].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[10].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[10].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(11, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[10].count }}</p>
                <button @click="changeItemAmount(11, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/お値打ち弁当/2種類の焼魚弁当.jpg"
            alt="2種類の焼魚弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[11].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[11].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[11].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(12, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[11].count }}</p>
                <button @click="changeItemAmount(12, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/お値打ち弁当/鯖の竜田揚げ胡麻だれポン酢弁当.jpg"
            alt="鯖の竜田揚げ胡麻だれポン酢弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[12].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[12].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[12].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(13, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[12].count }}</p>
                <button @click="changeItemAmount(13, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/お値打ち弁当/鶏の唐揚胡麻味噌だれ弁当.jpg"
            alt="鶏の唐揚胡麻味噌だれ弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[13].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[13].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[13].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(14, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[13].count }}</p>
                <button @click="changeItemAmount(14, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/お値打ち弁当/トラウトサーモンの昆布照焼ソース弁当.jpg"
            alt="トラウトサーモンの昆布照焼ソース弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[14].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[14].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[14].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(15, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[14].count }}</p>
                <button @click="changeItemAmount(15, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/お値打ち弁当/鯖の味噌煮弁当.jpg"
            alt="鯖の味噌煮弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[15].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[15].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[15].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(16, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[15].count }}</p>
                <button @click="changeItemAmount(16, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/お値打ち弁当/鶏胸肉の梅肉ドレッシング弁当.jpg"
            alt="鶏胸肉の梅肉ドレッシング弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[16].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[16].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[16].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(17, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[16].count }}</p>
                <button @click="changeItemAmount(17, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/お値打ち弁当/塩麹！豚の生姜焼弁当.jpg"
            alt="塩麹！豚の生姜焼弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[17].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[17].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[17].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(18, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[17].count }}</p>
                <button @click="changeItemAmount(18, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/お値打ち弁当/鮭わかめご飯弁当.jpg"
            alt="鮭わかめご飯弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[18].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[18].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[18].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(19, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[18].count }}</p>
                <button @click="changeItemAmount(19, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/お値打ち弁当/親子鶏そぼろ弁当.jpg"
            alt="親子鶏そぼろ弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[19].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[19].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[19].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(20, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[19].count }}</p>
                <button @click="changeItemAmount(20, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/お値打ち弁当/梅尽くし弁当.jpg"
            alt="梅尽くし弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[20].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[20].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[20].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(21, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[20].count }}</p>
                <button @click="changeItemAmount(21, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        "
      </ul>
      <div class="title_kamado">
        <h2>おむすび弁当</h2>
        <div class="title_kamado_p">※おむすび弁当のおむすびは170円と190円のおむすびからお選びいただけます。210円以上は190円との差額追加で選択可能です。「ご注文の手続きへ」にお進みいただき、ご要望欄にご希望があればご記入ください。
        </div>
      </div>
      <ul class="menu_kamado">
        <li>
          <img
            src="../assets/おむすび弁当/さくら.jpg"
            alt="さくら"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[21].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[21].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[21].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(22, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[21].count }}</p>
                <button @click="changeItemAmount(22, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/おむすび弁当/かえで.jpg"
            alt="かえで"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[22].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[22].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[22].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(23, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[22].count }}</p>
                <button @click="changeItemAmount(23, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/おむすび弁当/飛鳥弁当.jpg"
            alt="飛鳥弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[23].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[23].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[23].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(24, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[23].count }}</p>
                <button @click="changeItemAmount(24, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/おむすび弁当/王子弁当.jpg"
            alt="王子弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[24].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[24].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[24].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(25, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[24].count }}</p>
                <button @click="changeItemAmount(25, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/おむすび弁当/都電弁当.jpg"
            alt="都電弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[25].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[25].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[25].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(26, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[25].count }}</p>
                <button @click="changeItemAmount(26, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/おむすび弁当/明治弁当.jpg"
            alt="明治弁当"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[26].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[26].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[26].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(27, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[26].count }}</p>
                <button @click="changeItemAmount(27, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/おむすび弁当/飛鳥弁当(お茶付).jpg"
            alt="飛鳥弁当(お茶付)"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[27].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[27].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[27].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(28, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[27].count }}</p>
                <button @click="changeItemAmount(28, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/おむすび弁当/王子弁当(お茶付).jpg"
            alt="王子弁当(お茶付)"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[28].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[28].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[28].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(29, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[28].count }}</p>
                <button @click="changeItemAmount(29, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/おむすび弁当/都電弁当(お茶付).jpg"
            alt="都電弁当(お茶付)"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[29].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[29].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[29].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(30, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[29].count }}</p>
                <button @click="changeItemAmount(30, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="../assets/おむすび弁当/明治弁当(お茶付).jpg"
            alt="明治弁当(お茶付)"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfBento[30].name }}</p>
            <p>
              <span class="menu_price">
                {{ menuOfBento[30].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfBento[30].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmount(31, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfBento[30].count }}</p>
                <button @click="changeItemAmount(31, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li></li><li></li>
      </ul>
    </div>
    <div v-if="selectedMenu === 'omusubi'">
      <div class="title_kamado">
        <h2>無農薬酵素玄米おむすび</h2>
      </div>
      <ul class="menu_kamado">
        <li>
          <img
            src="..\assets\おむすび\酵素玄米\酵素玄米塩むすび.jpg"
            alt="酵素玄米塩むすび"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfOmusubi[0].name }}</p>
            <p>
              <span class="menu_price"> {{ menuOfOmusubi[0].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfOmusubi[0].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmountforOmusubi(32, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfOmusubi[0].count }}</p>
                <button @click="changeItemAmountforOmusubi(32, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="..\assets\おむすび\酵素玄米\酵素玄米鮭.jpg"
            alt="酵素玄米鮭"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfOmusubi[1].name }}</p>
            <p>
              <span class="menu_price"> {{ menuOfOmusubi[1].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfOmusubi[1].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmountforOmusubi(33, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfOmusubi[1].count }}</p>
                <button @click="changeItemAmountforOmusubi(33, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="..\assets\おむすび\酵素玄米\酵素玄米昆布.jpg"
            alt="酵素玄米昆布"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfOmusubi[2].name }}</p>
            <p>
              <span class="menu_price"> {{ menuOfOmusubi[2].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfOmusubi[2].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmountforOmusubi(34, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfOmusubi[2].count }}</p>
                <button @click="changeItemAmountforOmusubi(34, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src="..\assets\おむすび\酵素玄米\酵素玄米梅.jpg"
            alt="酵素玄米梅"
            class="bento_image"
          />
          <div class="menu_title_box">
            <p class="title">{{ menuOfOmusubi[3].name }}</p>
            <p>
              <span class="menu_price"> {{ menuOfOmusubi[3].price }} </span
              >円
              <span class="menu_price_tax">
                (税込：{{ (menuOfOmusubi[3].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class="count_container">
            <p class="count_title">注文個数</p>
            <div>
              <div class="count_main">
                <button @click="changeItemAmountforOmusubi(35, -1)" class="count_minus">
                  -
                </button>
                <p class="count_number">{{ menuOfOmusubi[3].count }}</p>
                <button @click="changeItemAmountforOmusubi(35, 1)" class="count_plus">
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="title_kamado">
        <h2>特別栽培白米おむすび</h2>
      </div>
      <ul class="menu_kamado">
        <li>
          <img
            src='..\assets\おむすび\白米\こだわりの塩むすび.jpg'
            alt='こだわりの塩むすび'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[4].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[4].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[4].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(36, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[4].count }}</p>
                <button @click='changeItemAmountforOmusubi(36, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\わかめごはん.jpg'
            alt='わかめごはん'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[5].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[5].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[5].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(37, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[5].count }}</p>
                <button @click='changeItemAmountforOmusubi(37, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\和風ツナ.jpg'
            alt='和風ツナ'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[6].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[6].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[6].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(38, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[6].count }}</p>
                <button @click='changeItemAmountforOmusubi(38, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<!-- <li>
          <img
            src='..\assets\おむすび\白米\海苔佃煮.jpg'
            alt='海苔佃煮'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[7].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[7].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[7].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(39, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[7].count }}</p>
                <button @click='changeItemAmountforOmusubi(39, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li> -->
<li>
          <img
            src='..\assets\おむすび\白米\茄子ごま肉味噌.jpg'
            alt='茄子ごま肉味噌'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[8].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[8].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[8].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(40, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[8].count }}</p>
                <button @click='changeItemAmountforOmusubi(40, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\鶏そぼろ.jpg'
            alt='鶏そぼろ'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[9].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[9].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[9].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(41, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[9].count }}</p>
                <button @click='changeItemAmountforOmusubi(41, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\鶏の唐揚.jpg'
            alt='鶏の唐揚'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[10].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[10].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[10].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(42, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[10].count }}</p>
                <button @click='changeItemAmountforOmusubi(42, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\昆布佃煮.jpg'
            alt='昆布佃煮'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[11].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[11].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[11].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(43, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[11].count }}</p>
                <button @click='changeItemAmountforOmusubi(43, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\おかか.jpg'
            alt='おかか'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[12].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[12].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[12].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(44, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[12].count }}</p>
                <button @click='changeItemAmountforOmusubi(44, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\玉子焼.jpg'
            alt='玉子焼'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[13].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[13].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[13].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(45, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[13].count }}</p>
                <button @click='changeItemAmountforOmusubi(45, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\鶏七味.jpg'
            alt='鶏七味'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[14].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[14].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[14].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(46, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[14].count }}</p>
                <button @click='changeItemAmountforOmusubi(46, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\炙り鮭.jpg'
            alt='炙り鮭'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[15].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[15].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[15].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(47, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[15].count }}</p>
                <button @click='changeItemAmountforOmusubi(47, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\炙り鮭と三つ葉.jpg'
            alt='炙り鮭と三つ葉'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[16].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[16].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[16].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(48, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[16].count }}</p>
                <button @click='changeItemAmountforOmusubi(48, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\龍神梅.jpg'
            alt='龍神梅'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[17].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[17].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[17].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(49, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[17].count }}</p>
                <button @click='changeItemAmountforOmusubi(49, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\味噌ひじき.jpg'
            alt='味噌ひじき'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[18].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[18].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[18].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(50, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[18].count }}</p>
                <button @click='changeItemAmountforOmusubi(50, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\赤飯.jpg'
            alt='赤飯'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[19].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[19].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[19].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(51, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[19].count }}</p>
                <button @click='changeItemAmountforOmusubi(51, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\辛子明太子.jpg'
            alt='辛子明太子'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[20].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[20].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[20].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(52, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[20].count }}</p>
                <button @click='changeItemAmountforOmusubi(52, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\焼たらこ.jpg'
            alt='焼たらこ'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[21].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[21].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[21].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(53, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[21].count }}</p>
                <button @click='changeItemAmountforOmusubi(53, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\梅ちりめん.jpg'
            alt='梅ちりめん'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[22].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[22].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[22].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(54, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[22].count }}</p>
                <button @click='changeItemAmountforOmusubi(54, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\しそちりめん.jpg'
            alt='しそちりめん'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[23].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[23].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[23].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(55, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[23].count }}</p>
                <button @click='changeItemAmountforOmusubi(55, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\海老天.jpg'
            alt='海老天'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[24].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[24].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[24].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(56, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[24].count }}</p>
                <button @click='changeItemAmountforOmusubi(56, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\うなぎ.jpg'
            alt='うなぎ'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[25].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[25].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[25].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(57, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[25].count }}</p>
                <button @click='changeItemAmountforOmusubi(57, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\白米\カレーばくだん.jpg'
            alt='カレーばくだん'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[26].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[26].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[26].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(58, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[26].count }}</p>
                <button @click='changeItemAmountforOmusubi(58, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li></li><li></li>
      </ul>
        <div class="title_kamado">
        <h2>無農薬玄米おむすび</h2>
        </div>
        <ul class="menu_kamado">
        <li>
          <img
            src='..\assets\おむすび\玄米\茄子ごま肉味噌.jpg'
            alt='茄子ごま肉味噌'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[27].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[27].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[27].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(59, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[27].count }}</p>
                <button @click='changeItemAmountforOmusubi(59, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\玄米\鶏そぼろ.jpg'
            alt='鶏そぼろ'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[28].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[28].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[28].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(60, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[28].count }}</p>
                <button @click='changeItemAmountforOmusubi(60, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\玄米\おかか.jpg'
            alt='おかか'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[29].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[29].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[29].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(61, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[29].count }}</p>
                <button @click='changeItemAmountforOmusubi(61, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\玄米\炙り鮭.jpg'
            alt='炙り鮭'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[30].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[30].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[30].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(62, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[30].count }}</p>
                <button @click='changeItemAmountforOmusubi(62, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\玄米\龍神梅.jpg'
            alt='龍神梅'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[31].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[31].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[31].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(63, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[31].count }}</p>
                <button @click='changeItemAmountforOmusubi(63, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\玄米\炙り鮭と三つ葉.jpg'
            alt='炙り鮭と三つ葉'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[32].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[32].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[32].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(64, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[32].count }}</p>
                <button @click='changeItemAmountforOmusubi(64, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
<li>
          <img
            src='..\assets\おむすび\玄米\玄米雑穀.jpg'
            alt='玄米雑穀'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[33].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[33].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[33].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(65, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[33].count }}</p>
                <button @click='changeItemAmountforOmusubi(65, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li></li>
      </ul>
      <div class="title_kamado">
        <h2>おむすび容器</h2>
      </div>
      <ul class="menu_kamado">
        <li>
          <img
            src='..\assets\お惣菜\盛合せ容器(大皿).jpg'
            alt='盛合せ容器(大皿)'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[34].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[34].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[34].price* 1.1).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(66, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[34].count }}</p>
                <button @click='changeItemAmountforOmusubi(66, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\個包装.jpg'
            alt='個包装'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOmusubi[35].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOmusubi[35].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOmusubi[35].price* 1.1).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOmusubi(67, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOmusubi[35].count }}</p>
                <button @click='changeItemAmountforOmusubi(67, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li></li><li></li>
      </ul>
    </div>
    <div v-if="selectedMenu === 'side'">
      <ul class="menu_kamado">
        <li>
          <img
            src='..\assets\お惣菜\おこげせんべい.jpg'
            alt='おこげせんべい'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[0].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[0].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[0].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(68, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[0].count }}</p>
                <button @click='changeItemAmountforOsouzai(68, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\鶏の唐揚.jpg'
            alt='鶏の唐揚'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[1].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[1].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[1].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(69, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[1].count }}</p>
                <button @click='changeItemAmountforOsouzai(69, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\厚焼たまご.jpg'
            alt='厚焼たまご'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[2].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[2].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[2].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(70, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[2].count }}</p>
                <button @click='changeItemAmountforOsouzai(70, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\切干大根.jpg'
            alt='切干大根'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[3].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[3].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[3].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(71, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[3].count }}</p>
                <button @click='changeItemAmountforOsouzai(71, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\味噌ひじき.jpg'
            alt='味噌ひじき'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[4].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[4].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[4].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(72, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[4].count }}</p>
                <button @click='changeItemAmountforOsouzai(72, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\ほうれん草の胡麻和え.jpg'
            alt='ほうれん草の胡麻和え'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[5].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[5].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[5].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(73, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[5].count }}</p>
                <button @click='changeItemAmountforOsouzai(73, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\南瓜とｿｰｾｰｼﾞのｽﾓｰｸｻﾗﾀﾞ.jpg'
            alt='南瓜とｿｰｾｰｼﾞのｽﾓｰｸｻﾗﾀﾞ'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[6].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[6].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[6].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(74, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[6].count }}</p>
                <button @click='changeItemAmountforOsouzai(74, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\きのこの黒胡椒炒め.jpg'
            alt='きのこの黒胡椒炒め'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[7].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[7].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[7].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(75, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[7].count }}</p>
                <button @click='changeItemAmountforOsouzai(75, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\煮物.jpg'
            alt='煮物'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[8].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[8].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[8].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(76, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[8].count }}</p>
                <button @click='changeItemAmountforOsouzai(76, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\鶏胸肉の梅肉サラダ.jpg'
            alt='鶏胸肉の梅肉サラダ'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[9].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[9].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[9].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(77, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[9].count }}</p>
                <button @click='changeItemAmountforOsouzai(77, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\キャベツの塩麹浅漬け.jpg'
            alt='キャベツの塩麹浅漬け'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[10].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[10].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[10].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(78, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[10].count }}</p>
                <button @click='changeItemAmountforOsouzai(78, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <!-- <li>
          <img
            src='..\assets\お惣菜\味噌汁.jpg'
            alt='味噌汁'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[11].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[11].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[11].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(79, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[11].count }}</p>
                <button @click='changeItemAmountforOsouzai(79, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li> -->
        <li>
          <img
            src='..\assets\お惣菜\赤飯(一人前200g).jpg'
            alt='赤飯（一人前200ｇ）'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[12].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[12].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[12].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(80, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[12].count }}</p>
                <button @click='changeItemAmountforOsouzai(80, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\赤飯(一人前200g)_2.jpg'
            alt='赤飯(一人前200g)'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[13].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[13].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[13].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(81, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[13].count }}</p>
                <button @click='changeItemAmountforOsouzai(81, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\お茶.jpg'
            alt='お茶'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[14].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[14].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[14].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(82, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[14].count }}</p>
                <button @click='changeItemAmountforOsouzai(82, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\お茶.jpg'
            alt='お茶'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[15].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[15].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[15].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(83, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[15].count }}</p>
                <button @click='changeItemAmountforOsouzai(83, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\お茶.jpg'
            alt='お茶'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[16].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[16].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[16].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(84, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[16].count }}</p>
                <button @click='changeItemAmountforOsouzai(84, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\お茶.jpg'
            alt='お茶'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[17].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[17].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[17].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(85, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[17].count }}</p>
                <button @click='changeItemAmountforOsouzai(85, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\惣菜盛合せ(中).jpg'
            alt='お惣菜\惣菜盛合せ(中)'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[18].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[18].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[18].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(86, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[18].count }}</p>
                <button @click='changeItemAmountforOsouzai(86, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img
            src='..\assets\お惣菜\惣菜盛合せ(大).jpg'
            alt='お惣菜\惣菜盛合せ(大)'
            class='bento_image'
          />
          <div class='menu_title_box'>
            <p class='title'>{{ menuOfOsouzai[19].name }}</p>
            <p>
              <span class='menu_price'> {{menuOfOsouzai[19].price }} </span
              >円
              <span class='menu_price_tax'>
                (税込：{{ (menuOfOsouzai[19].price* 1.08).toFixed(1) }}円)
              </span>
            </p>
          </div>
          <div class='count_container'>
            <p class='count_title'>注文個数</p>
            <div>
              <div class='count_main'>
                <button @click='changeItemAmountforOsouzai(87, -1)' class='count_minus'>
                  -
                </button>
                <p class='count_number'>{{ menuOfOsouzai[19].count }}</p>
                <button @click='changeItemAmountforOsouzai(87, 1)' class='count_plus'>
                  +
                </button>
              </div>
            </div>
          </div>
        </li>
        <li></li>
      </ul>
    </div>
    <router-link :to="{ name: 'CustomerForm' }">
      <div class="next_btn_container">
        <btn class="next_btn">▶ ご注文の手続きへ</btn>
      </div>
    </router-link>
  </div>
</template>
<script>
import { ref, inject, toRef } from "vue";
import "@fontsource/noto-sans-jp";
export default {
  name: "MenuList",
  setup() {
    const { menuState, total, calculateTotal, calculateTotalofPack } = inject("menuStore");
    const menuOfBento = toRef(menuState, "menuOfBento");
    const menuOfOmusubi = toRef(menuState, "menuOfOmusubi");
    const menuOfOsouzai = toRef(menuState, "menuOfOsouzai");
    const cart = toRef(menuState, "cart");
    const cartofPack = toRef(menuState, "cartofPack");
    const selectedMenu = ref("bento");

    const onClickBentoMenu = () => {
      selectedMenu.value = "bento";
    };
    const onClickOmusubiMenu = () => {
      selectedMenu.value = "omusubi";
    };
    const onClickSideMenu = () => {
      selectedMenu.value = "side";
    };

    const changeItemAmount = (itemId, amount) => {
      const item = menuOfBento.value.find((i) => i.id === itemId);
      if (item) {
        if(item.count <= 0 && amount < 0){
          item.count = 0
        }else{
          item.count += amount;
          if (item.count > 0) {
            const existingCartItem = cart.value.find((ci) => ci.id === itemId);
            if (existingCartItem) {
              existingCartItem.count = item.count;
              existingCartItem.totalPrice = item.count * item.price;
            } else {
              cart.value.push({
                id: item.id,
                name: item.name,
                price: item.price,
                count: item.count,
                totalPrice: item.count * item.price,
              });
            }
            calculateTotal(cart.value);
          } else {
            const index = cart.value.findIndex((ci) => ci.id === itemId);
            if (index !== -1) {
              cart.value.splice(index, 1);
            }
          }
        }
      }
    };

    const changeItemAmountforOmusubi = (itemId, amount) => {
      const item = menuOfOmusubi.value.find((i) => i.id === itemId);
      if (item) {
        if(item.count <= 0 && amount < 0){
          item.count = 0
        }else{
          item.count += amount;
          if (item.count > 0) {
              const existingCartItem = cart.value.find((ci) => ci.id === itemId);
              if (existingCartItem) {
                existingCartItem.count = item.count;
                existingCartItem.totalPrice = item.count * item.price;
              } else {
                cart.value.push({
                  id: item.id,
                  name: item.name,
                  price: item.price,
                  count: item.count,
                  totalPrice: item.count * item.price,
                });
              }
              calculateTotal(cart.value);
          } else {
            const index = cart.value.findIndex((ci) => ci.id === itemId);
            if (index !== -1) {
              cart.value.splice(index, 1);
            }
          }
        }
      }
    };
    const changeItemAmountforPack = (itemId, amount) => {
      const item = menuOfOmusubi.value.find((i) => i.id === itemId);
      if (item) {
        if(item.count <= 0 && amount < 0){
          item.count = 0
        }else{
          item.count += amount;
          if (item.count > 0) {
              const existingCartItem = cartofPack.value.find((ci) => ci.id === itemId);
              if (existingCartItem) {
                existingCartItem.count = item.count;
                existingCartItem.totalPrice = item.count * item.price;
              } else {
                cartofPack.value.push({
                  id: item.id,
                  name: item.name,
                  price: item.price,
                  count: item.count,
                  totalPrice: item.count * item.price,
                });
              }
              calculateTotalofPack(cartofPack.value);
          } else {
            const index = cartofPack.value.findIndex((ci) => ci.id === itemId);
            if (index !== -1) {
              cartofPack.value.splice(index, 1);
            }
          }
        }
      }
    }

    const changeItemAmountforOsouzai = (itemId, amount) => {
      const item = menuOfOsouzai.value.find((i) => i.id === itemId);
      if (item) {
        if(item.count <= 0 && amount < 0){
          item.count = 0
        }else{
          item.count += amount;
          if (item.count > 0) {
            const existingCartItem = cart.value.find((ci) => ci.id === itemId);
            if (existingCartItem) {
              existingCartItem.count = item.count;
              existingCartItem.totalPrice = item.count * item.price;
            } else {
              cart.value.push({
                id: item.id,
                name: item.name,
                price: item.price,
                count: item.count,
                totalPrice: item.count * item.price,
              });
            }
            calculateTotal(cart.value);
          } else {
            const index = cart.value.findIndex((ci) => ci.id === itemId);
            if (index !== -1) {
              cart.value.splice(index, 1);
            }
          }
        }
      }
    };

    return {
      onClickBentoMenu,
      onClickOmusubiMenu,
      onClickSideMenu,
      selectedMenu,
      changeItemAmount,
      changeItemAmountforOmusubi,
      changeItemAmountforPack,
      changeItemAmountforOsouzai,
      menuOfBento,
      menuOfOmusubi,
      menuOfOsouzai,
      total,
    };
  },
};
</script>
<style>
.tab_list_containier{
  padding: 1vw 1vw 0 0;
}
.menu_container {
  padding:5vw;
  width: 100%;
  margin: 0 auto;
  font-family: "Noto Sans JP";
  inherits: 1.6;
}
.text-center {
  color: #333;
  letter-spacing: 5px;
}
.tab_list {
  margin: 0 -15px 20px;
  padding: 0 15px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  list-style-type: none;
  font-weight:600;
  letter-spacing:2px;
}
.tab_li{
    margin-left: 0;
    max-width: 100%;
    width: 24.4%;
    line-height: 1.4;
    border-color: #999;
    padding: 5px 5px 0;
    border: 2px solid #eee;
    border-bottom: 0;
    border-radius: 12px 12px 0 0;
    background-color: #efefef;
    cursor: pointer;
    transition: border-color 0.15s;
    z-index: 1;
    & span {
      padding: 4px 6px 6px;
      font-size: 9px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      border-radius: 6px 6px 0 0;
      transition: border-color 0.15s;
      -webkit-box-pack: vertical;
      -webkit-box-direction: normal;
  }
}
.tab_selected{
  margin-left: 0;
    max-width: 100%;
    width: 24.4%;
    line-height: 1.4;
    border-color: #999;
    padding: 5px 5px 0;
    border: 2px solid #eee;
    border-bottom: 0;
    border-radius: 12px 12px 0 0;
    background-color: #efefef;
    cursor: pointer;
    transition: border-color 0.15s;
    z-index: 1;
    & span {
      background-color: #efe8e0;
      padding: 4px 6px 6px;
      font-size: 9px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      border-radius: 6px 6px 0 0;
      transition: border-color 0.15s;
      -webkit-box-pack: vertical;
      -webkit-box-direction: normal;
  }
}
.title_kamado {
  margin: 35px 0 20px;
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 0.3em;
  border-bottom: 2px solid #ccc;
  & h2 {
    line-height: 1.2;
  }
}
.title_kamado_p {
  font-size: 16px;
  font-weight: 500;
}
.menu_kamado {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & li {
    width: 22%;
    display: block;
    @media screen and (max-width: 767px) {
      width: 45%;
  }
  }
  & img {
    border: 1px solid #ccc;
    width: 100%;
    margin: 0 auto;
    border-radius: 3%;
  }
}
.menu_title_box {
  margin-top: 8px;
  line-height: 1.7;
  & p {
    font-size: 14px;
    display: block;
  }
}
.menu_price {
  font-size: 14px;
}
.menu_price_tax {
  font-size: 12px;
}
.count_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
}
.count_main {
  display: inline-flex;
  align-items: center;
}
.count_minus {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 100%;
  cursor: pointer;
}
.count_number {
  width: 52px;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  line-height: 140%;
}
.count_plus {
  width: 40px;
  height: 40px;
  border: 1px solid #222;
  border-radius: 100%;
  cursor: pointer;
}
.count_title {
  color: #666;
  font-size: 14px;
  line-height: 140%;
}
.next_btn {
  display: inline-block;
  padding: 8px 30px 10px 46px;
  border-radius: 22px;
  font-size: 20px;
  background: red;
  background-repeat: no-repeat;
  background-position: 16px 50%;
  background-size: 18px 18px;
  text-align: center;
  font-weight: 700;
  line-height: 1.4;
  cursor: pointer;
  color: white;
  transition: color 0.15s ease-in;
}
.next_btn_container {
  text-align: center;
}
</style>
